'use strict';

// const GraphQLClient = require("graphql-request");

//
// const client = new GraphQLClient('https://api.graph.cool/simple/v1/cj683sijb3q4x0108icl2mi35', {
//   headers: {
//     Authorization: 'Bearer my-jwt-token',
//   },
// });
//
// const query = `{
//     Movie(title: "Inception") {
//       releaseDate
//       actors {
//         name
//       }
//     }
//   }`;
// client.request(query).then(data => console.log(data));

// Initialize Firebase
// var fbconfig = {
//   apiKey: "AIzaSyAjxbX3sUoNaxihQtU22as7i2Kqjpa3nm4",
//   authDomain: "lemtest01.firebaseapp.com",
//   databaseURL: "https://lemtest01.firebaseio.com",
//   projectId: "lemtest01",
//   storageBucket: "lemtest01.appspot.com",
//   messagingSenderId: "669615412690"
// };
//
// var app = firebase.initializeApp(fbconfig);
// var database = app.database();
// var CUSTOMERREFPATH = "customers"
//
// exports.subscribe = function(app, "firebase") {
//
//   let createUser = function(email, password) {
//     firebase.auth().createUserWithEmailAndPassword(email, password)
//       .catch(function(error) {
//         // Handle Errors here.
//         var errorCode = error.code;
//         var errorMessage = error.message;
//         // ...
//         console.log(errorCode + ' andANDand ' +
//           errorMessage);
//       });
//   }
//
//   let signIn = function(email, password, bogus) {
//     console.log('Oh my...');
//     firebase.auth().signInWithEmailAndPassword(email, password)
//     console.log('Holy shit!');
//     .catch(function(error) {
//       // Handle Errors here.
//       var errorCode = error.code;
//       var errorMessage = error.message;
//       // ...
//       console.log(errorCode + ' andANDand ' +
//         errorMessage);
//     });
//   }
//
//   let onAuthStateChanged = function(user) {
//     firebase.auth().onAuthStateChanged(function(user) {
//       if (user) {
//         // User is signed in.
//         var displayName = user.displayName;
//         var email = user.email;
//         var emailVerified = user.emailVerified;
//         var photoURL = user.photoURL;
//         var isAnonymous = user.isAnonymous;
//         var uid = user.uid;
//         var providerData = user.providerData;
//         // ...
//       } else {
//         // User is signed out.
//         // ...
//       }
//     });
//   }
//   firebase.auth().onAuthStateChanged(onAuthStateChanged);
//   app.ports.signIn.subscribe(signIn);
//   app.ports.signOut.subscribe(signOut);
//
// };

function signIn(email, password) {
  console.log('Holy fuck! ' + email + ' is who it was!');
  var returnString = 'Ehermahgerd I returned!';
  return returnString;
}

function signIn(email, password) {
   console.log('Holy fuck!');
}

// function addCustomer(customer) {
//   var promise = database
//     .ref(CUSTOMERREFPATH)
//     .push(customer);
//   return promise;
// }
//
// function updateCustomer(customer) {
//   var id = customer.id;
//   var promise = database
//     .ref(CUSTOMERREFPATH + "/" + id)
//     .set(customer);
//   return promise;
// }
//
// function deleteCustomer(customer) {
//   var id = customer.id;
//   var promise = database
//     .ref(CUSTOMERREFPATH + "/" + id)
//     .remove();
//   return promise;
// }
//
// function customerListener() {
//   return database.ref(CUSTOMERREFPATH);
// }
